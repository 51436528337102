.contact
    min-height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    background-image: url(../img/groundgame.webp)
    background-attachment: fixed
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    padding-top: 20vh
    overflow: hidden
    clip-path: polygon(0% 100%, 0% 15%, 100% 0%, 100% 100%)
    h3.question
        font-size: 62px
        color: white
        z-index: 1
        .contactGold
            color: gold
    h3
        font-size: 34px
        color: white
        z-index: 1
        margin-top: -10px      
    form
        z-index: 1
        font-size: 32px
        display: flex
        flex-flow: column nowrap
        margin: 4vh 0 6vh 0
        color: white
        width: 40%
        justify-content: center
        font-family: 'Oswald', sans-serif
        label
            margin: 8px
            color: white
        input
            width: 100%
            padding: 0
            margin: 0
            margin: 10px
            padding: 10px
            font-size: 28px
            background-color: transparent
            border: 2px solid white
            border-radius: 5px
            color: white
            margin: 5px
            font-family: 'Oswald', sans-serif
            &::placeholder
                color: white
        textarea
            width: 100%
            height: 150px
            resize: none
            font-size: 28px
            padding: 10px
            background-color: transparent
            border: 2px solid white
            border-radius: 5px
            color: white
            margin: 5px
            font-family: 'Oswald', sans-serif
            &::placeholder
                color: white
        button
            min-width: 10%
            margin: 15px
            background-color: transparent
            padding: 16px 24px
            font-size: 28px
            color: white
            border: 2px solid white
            border-radius: 5px
            align-self: center

    &::after
        content: ""
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        background-color: rgba(0, 0, 0, 0.7)
        z-index: 0

@media (max-width: 1200px) and (orientation: portrait)
    .contact
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)
        background-image: url(../img/groundgameipad.webp)
        form
            width: 70%
        &::after
            background-color: rgba(0, 0, 0, 0.6)
@media (max-width: 1000px) and (orientation: portrait)
       .contact
            padding-top: 10vh
            max-width: 100vw
            background-image: url(../img/groundgame2.webp)
            &::after
                background-color: rgba(0, 0, 0, 0.6)
            h3.question
                text-align: center
                line-height: 100%
                padding: 0 20px
                font-size: 50px
            h3
                text-align: center
                line-height: 100%
                margin-top: 30px
            form
                font-size: 32px
                margin: 4vh 0 6vh 0
                width: 80%
                font-family: 'Oswald', sans-serif
@media (max-width: 823px) and (orientation: landscape)
   .contact
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)
            padding-top: 10vh
            max-width: 100vw
            &::after
                background-color: rgba(0, 0, 0, 0.6)
            h3.question
                text-align: center
                line-height: 100%
                padding: 0 20px
                font-size: 50px
            h3
                text-align: center
                line-height: 100%
                margin-top: 30px
            form
                font-size: 32px
                margin: 4vh 0 6vh 0
                width: 80%
                font-family: 'Oswald', sans-serif 
