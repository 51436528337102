.map-responsive
    width: 100%
    position: relative
    height: 400px
    iframe
        left: 0
        top: 0
        height: 100%
        width: 100%
        position: absolute
