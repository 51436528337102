$break-point-horizontal1: "(max-width: 533px) and (orientation: landscape)"
$break-point-horizontal2: "(min-width: 534px) and (max-width: 731px) and (orientation: landscape)"
$break-point-horizontal3: "(min-width: 732px) and (max-width: 823px) and (orientation: landscape)"
    
header 
    position: relative
    background-image: url(../img/bjjbg.webp)
    background-attachment: fixed
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    height: 100vh
    +flex-display(column, center, wrap)
    align-items: center
    z-index: 1
    cursor: default
    min-width: 100%
    img
        z-index: 3
    h1
        color: white
        font-size: 80px
        padding: 0 80px 10px 80px
        border-bottom: 1px gold solid
        z-index: 3
        text-transform: uppercase
        span
            color: gold
    ul
        z-index: 3
        list-style: none
        display: flex
        li
            span
                font-size: 48px
                color: white
                text-align: center
                &::after
                    content: "\00B7"
                    color: gold
                    padding: 0 45px
            &:last-child
                span
                    &::after
                        content: ""
                        padding: 0
    div
        position: absolute
        left: 50%
        width: 40px
        height: 70px
        margin-left: -20px
        bottom: 10%
        margin-top: -35px
        box-shadow: inset 0 0 0 1px #fff
        border-radius: 25px
        z-index: 50
        &::before
            position: absolute
            left: 50%
            content: ''
            width: 8px
            height: 8px
            background: #fff
            margin-left: -4px
            top: 8px
            border-radius: 4px
            animation-duration: 1.6s
            animation-iteration-count: infinite
            animation-name: scroll
            z-index: 50
    &::after
        content: ""
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        background-color: rgba(0, 0, 0, 0.7)
        z-index: 0
@media #{$break-point1}
    header
        h1
            font-size: 34px
            padding: 10px 20px
        ul
            li
                padding-top: 6px
                span
                    font-size: 26px
                    &::after
                        padding: 0 15px
        div
            display: none
@media #{$break-point2}
    header
        h1
            font-size: 20px
            padding: 10px 20px
        ul
            li
                span
                    font-size: 32px
                    &::after
                        padding: 0 15px
@media #{$break-point3}
    header
        background-image: url(../img/bjjbgipad.webp)
        background-position: center
        background-repeat: no-repeat
        h1
            font-size: 80px
        ul
            li
                span
                    font-size: 50px
                    &::after
                        padding: 0 55px
        div
            display: none
@media #{$break-point4}
    header
        background-image: url(../img/bjjbgipad.webp)
        background-position: center
        background-repeat: no-repeat
        h1
            font-size: 120px
            padding: 0 50px 10px 50px
        ul
            li
                span
                    font-size: 68px
                    &::after
                        padding: 0 40px
@media #{$break-point-horizontal1} 
    header
        h1
            font-size: 32px
            padding: 40px 20px 5px 20px
        ul
            li
                span
                    font-size: 22px
                    &::after
                        padding: 0 15px
        div
            display: none
@media #{$break-point-horizontal2}
    header
        h1
            font-size: 26px
            padding: 3px 16px
        div
            display: none
@media #{$break-point-horizontal3}
    header
        background-image: url(../img/bjjbgipad.webp)
        background-position: center
        background-repeat: no-repeat
        h1
            font-size: 46px
        ul
            li
                span
                    font-size: 38px
                    &::after
                        padding: 0 45px
        div
            display: none
@media (width: 812px) and (orientation: landscape)
    header
        background-image: url(../img/bjjbgipad.webp)
        background-position: center
        background-repeat: no-repeat
        h1
            font-size: 46px
        ul
            li
                span
                    font-size: 20px
                    &::after
                        padding: 0 55px
        div
            display: none
@media (min-width: 1200px) and (max-width: 1366px) 
    header
        div
            bottom: 5%







                    