.footer
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    // margin-top: 40vh
    background-color: black
    height: 30vh
    .wrapper
        display: flex
        flex-direction: row
        align-items: center
        box-sizing: border-box
        width: 70%
        height: 100%
        .logo
            width: 25%
            margin: 4% 0
            display: flex
            justify-content: center
            align-items: center
            border-right: 1px solid gold
        .footerMain
            margin: 4% 0
            color: white
            display: flex
            flex-direction: column
            width: 50%
            .socials
                display: flex
                justify-content: center
                font-size: 42px
                a
                    margin: 15px
                    color: white
                    transition: .2s
                    &:hover
                        color: gold
                        transform: scale(1.1)
            .footerContact
                display: flex
                flex-direction: column
                align-items: center
                font-size: 20px
                user-select: text
            .clause
                display: flex
                flex-direction: column
                align-items: center
                font-size: 12px
        .gg
            width: 25%
            margin: 4% 0
            border-left: 1px solid gold
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            h4
                font-size: 32px
                margin-bottom: 10px
                color: white
                .bluetext
                    color: #00ADEE
            .pfr
                max-width: 200px
@media (max-width: 1200px) and (orientation: portrait)
    .footer
        .wrapper
            width: 100%
            .logo
                width: 25%
                margin: 4% 0
                img
                    max-width: 90%
            .footerMain
                width: 50%
                justify-content: center
                .footerContact
                    font-size: 32px
                .socials
                    font-size: 48px
@media (max-width: 1000px) and (orientation: portrait)
    .footer
        .wrapper
            width: 100%
            .logo
                width: 35%
                margin: 4% 0
                img
                    max-width: 90%
            .footerMain
                width: 65%
                .footerContact
                    font-size: 26px
                .socials
                    font-size: 42px
            .gg
                display: none
@media (max-width: 600px) and (orientation: portrait)
    .footer
        .wrapper
            width: 100%
            .logo
                width: 35%
                margin: 4% 0
                img
                    max-width: 90%
            .footerMain
                width: 65%
                .footerContact
                    font-size: 18px
                .socials
                    font-size: 36px
            .gg
                display: none
@media (max-width: 823px) and (orientation: landscape)
    .footer
        max-width: 100vw
        height: 50vh
        .wrapper
            width: 100%
            .logo
                width: 25%
                margin: 4% 0
                img
                    max-width: 90%
            .footerMain
                width: 50%
                justify-content: center
                .footerContact
                    font-size: 22px
                .socials
                    font-size: 28px
            .gg
                a
                    display: flex
                    justify-content: center
                    img
                        max-width: 80%

        
            
                    

        
        
        