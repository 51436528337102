.partners
    padding: 10% 0
    background-color: black
    color: white
    display: none
    flex-direction: column
    align-items: center
    z-index: 15
    h3
        font-size: 38px
        span
            color: #00ADEE
    img
        margin: 20px
    p
        font-size: 20px
        text-align: center
        margin: 0 10%
        font-weight: 700
@media (max-width: 1000px) and (orientation: portrait)
    .partners
        display: flex
        h3
            font-size: 56px
        p
            font-size: 32px
@media (max-width:  768px) and (orientation: portrait)
    .partners
        display: flex

@media (max-width: 568px) and (orientation: portrait)
    .partners
        display: flex
        h3 
            font-size: 32px
        p
            font-size: 16px