section.offer
    .pricing
        padding: 100px 40px 200px
        color: white
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        img
            max-width: 100%
        ul 
            font-size: 24px
            padding: 50px 0px
            list-style: none
            .question
                color: gold
    flex-flow: row wrap
    justify-content: center
    position: relative
    padding: 5vh 0 5vh 0
    background-color: black
    clip-path: polygon(100% 95%, 0% 100%, 0% 0%, 100% 0%)
    .sportPanels
        +flex-display(row, center, wrap)
        align-items: flex-start
        .sportPanel
            +flex-display(row, center, wrap)
            width: 30%
            margin: 1.33%
            z-index: 1
            img
                max-height: 400px
            div.description
               
                +flex-display(column, flex-start, nowrap)
                text-align: left
                h3
                    color: gold
                    font-size: 52px
                    margin: 0px 0px 5px 15px
                    text-transform: uppercase
                p
                    border-top: 3px solid gold
                    font-size: 28px
                    text-align: justify
                    color: white
                    padding: 10px
    &>div.buttons
        z-index: 1
        display: flex
        flex-direction: row
        justify-content: space-around
        width: 60%
        margin: 0 20% 10px 20%
        // align-items: space-around
        button
            width: 30%
            margin-top: 20px
            margin-bottom: 25px
            padding: 15px 20px
            background-color: transparent
            border: 2px solid gold
            color: white
            font-size: 24px
            transition: .2s
            cursor: pointer
            font-weight: 700
            &:hover
                background-color: gold
                transform: scale(1.1)
                color: black
    .wrapDown
        transition: .2s
        height: 0vh
        width: 100%
        background-color: black
        overflow: hidden
        color: white
        display: flex
        justify-content: center
        .calendarTable
            text-align: center
            transition: .4s
            font-size: 20px
            th
                color: gold
                margin-down: 3px
                font-size: 24px
            td
                border: 1px solid gold
                padding: 2px
                width: 200px
                height: 100px     
        .price
            font-size: 28px
            color: gold
            th
                padding: 20px
            td
                text-align: center
                span
                    font-size: 14px  
    .wrapedDown
        height: 60vh

    

@media (max-width: 1200px) and (orientation: portrait)
    section.offer
        .pricing
            padding: 40px 20px 60px
        padding: 5vh 0 5vh 0
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)
        .sportPanels
            display: flex
            flex-direction: row
            max-width: 100vw
            .sportPanel
                box-sizing: border-box
                padding: 15px
                +flex-display(row, center, wrap)
                width: 50%
                z-index: 1
                margin:  0
                img
                    max-width: 100%
                div.description
                    min-height: auto              
                    h3
                        color: gold
                        font-size: 32px                      
                    p
                        border-top: 3px solid gold
                        font-size: 22px
        &>div.buttons
            flex-direction: row
            align-items: center
            button
                z-index: 1
                width: 50%
                margin: 10px
                padding: 15px 20px
                background-color: transparent
                border: 2px solid gold
                color: white
                font-size: 18px
                &:hover
                    background-color: gold
                    transform: scale(1.1)
                    color: black
        .wrapDown
            .calendarTable
                overflow: scroll
                font-size: 14px
                th
                    color: gold
                    margin-down: 3px
                    font-size: 24px
                td
                    border: 1px solid gold
                    padding: 2px
                    width: 200px
                    height: 100px     
            .price
                font-size: 14px
                th
                    padding: 8px
        .wrapedDown
            height: 45vh
@media (max-width: 600px) and (orientation: portrait)
    section.offer
        .pricing
            padding: 40px 20px 60px
        flex-flow: column
        overflow: hidden
        padding: 5vh 0 5vh 0
        width: 100vw
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)
        .sportPanels
            display: flex
            flex-direction: column
            max-width: 100vw
            .sportPanel
                +flex-display(row, center, wrap)
                width: 100%
                z-index: 1
                margin: 0
                img
                    max-width: 100%
                div.description              
                    h3
                        color: gold
                        font-size: 32px                      
                    p
                        border-top: 3px solid gold
                        font-size: 22px
        &>div.buttons
            z-index: 1
            display: flex
            flex-direction: column
            justify-content: space-around
            align-items: center
            width: 100vw
            margin: 0
            button
                z-index: 1
                width: 60%
                padding: 15px 20px
                background-color: transparent
                border: 2px solid gold
                color: white
                font-size: 20px
                &:hover
                    background-color: gold
                    transform: scale(1.1)
                    color: black
        .wrapDown
            .calendarTable
                overflow: scroll
                font-size: 14px
                th
                    color: gold
                    margin-down: 3px
                    font-size: 24px
                td
                    border: 1px solid gold
                    padding: 2px
                    width: 200px
                    height: 100px     
            .price
                font-size: 14px
                th
                    padding: 8px
        .wrapedDown
            height: 70vh
@media (max-width: 823px) and (orientation: landscape)
    section.offer
        .pricing
            padding: 40px 20px 60px
        width: 100vw
        padding-bottom: 10vh
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)
        .sportPanels
            display: flex
            flex-direction: row
            max-width: 100vw
            justify-content: center
            .sportPanel
                +flex-display(row, center, wrap)
                width: 48%
                z-index: 1
                margin: 1%
                img
                    max-width: 100%
                div.description              
                    h3
                        color: gold
                        font-size: 32px                      
                    p
                        border-top: 3px solid gold
                        font-size: 22px
        &>div.buttons
            z-index: 1
            display: flex
            flex-direction: column
            justify-content: space-around
            align-items: center
            width: 100vw
            margin: 0
            button
                width: 60%
                padding: 15px 20px
                background-color: transparent
                border: 2px solid gold
                color: white
                font-size: 20px
                z-index: 1
                &:hover
                    background-color: gold
                    transform: scale(1.1)
                    color: black
        .wrapDown
            .calendarTable
                overflow: scroll
                font-size: 14px
                th
                    color: gold
                    margin-down: 3px
                    font-size: 24px
                td
                    border: 1px solid gold
                    padding: 2px
                    width: 200px
                    height: 100px     
            .price
                font-size: 14px
                th
                    padding: 8px
        .wrapedDown
            height: 130vh
@media (max-width: 1200px) and (orientation: landscape)
    .pricing
        padding: 40px 20px 60px
    section.offer
        width: 100vw
        padding-bottom: 10vh
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)
        .sportPanels
            display: flex
            flex-direction: row
            max-width: 100vw
            justify-content: center
            .sportPanel
                +flex-display(row, center, wrap)
                width: 48%
                z-index: 1
                margin: 1%
                img
                    max-width: 100%
                div.description              
                    h3
                        color: gold
                        font-size: 32px                      
                    p
                        border-top: 3px solid gold
                        font-size: 22px
        &>div.buttons
            z-index: 1
            display: flex
            flex-direction: column
            justify-content: space-around
            align-items: center
            width: 100vw
            margin: 0
            button
                width: 60%
                padding: 15px 20px
                background-color: transparent
                border: 2px solid gold
                color: white
                font-size: 20px
                z-index: 1
                &:hover
                    background-color: gold
                    transform: scale(1.1)
                    color: black
        .wrapDown
            .calendarTable
                overflow: scroll
                font-size: 14px
                th
                    color: gold
                    margin-down: 3px
                    font-size: 24px
                td
                    border: 1px solid gold
                    padding: 2px
                    width: 200px
                    height: 100px     
            .price
                font-size: 14px
                th
                    padding: 8px
        .wrapedDown
            height: 60vh
@media (max-width: 1280px) and (orientation: landscape)
    .pricing
        padding: 40px 20px 60px
    section.offer
        width: 100vw
        padding-bottom: 10vh
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)
        .sportPanels
            display: flex
            flex-direction: row
            max-width: 100vw
            justify-content: center
            .sportPanel
                +flex-display(row, center, wrap)
                width: 48%
                z-index: 1
                margin: 1%
                img
                    max-width: 100%
                div.description    
                    min-height: auto
                    padding: 15px        
                    h3
                        color: gold
                        font-size: 32px                      
                    p
                        border-top: 3px solid gold
                        font-size: 22px
        &>div.buttons
            z-index: 1
            display: flex
            flex-direction: row
            justify-content: space-around
            align-items: center
            width: 100vw
            margin: 0
            button
                width: 30%
                padding: 15px 20px
                background-color: transparent
                border: 2px solid gold
                color: white
                font-size: 20px
                z-index: 1
                &:hover
                    background-color: gold
                    transform: scale(1.1)
                    color: black
        .wrapDown
            .calendarTable
                font-size: 14px
                th
                    color: gold
                    margin-down: 3px
                    font-size: 24px
                td
                    border: 1px solid gold
                    padding: 2px
                    width: 200px
                    height: 100px     
            .price
                font-size: 32px
                th
                    padding: 8px
        .wrapedDown
            height: 70vh
@media (min-width: 1281px) and (max-width: 1440px) and (orientation: landscape)
    section.offer
        .pricing
            padding: 40px 20px 60px
        width: 100vw
        padding-bottom: 10vh
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)
        .sportPanels
            display: flex
            flex-direction: row
            max-width: 100vw
            justify-content: center
            .sportPanel
                +flex-display(row, center, wrap)
                width: 48%
                z-index: 1
                margin: 1%
                img
                    max-width: 100%
                div.description    
                    min-height: auto
                    padding: 15px        
                    h3
                        color: gold
                        font-size: 32px                      
                    p
                        border-top: 3px solid gold
                        font-size: 22px
        &>div.buttons
            z-index: 1
            display: flex
            flex-direction: column
            justify-content: space-around
            align-items: center
            width: 100vw
            margin: 0
            button
                width: 60%
                padding: 15px 20px
                background-color: transparent
                border: 2px solid gold
                color: white
                font-size: 20px
                z-index: 1
                &:hover
                    background-color: gold
                    transform: scale(1.1)
                    color: black
        .wrapDown
            .calendarTable
                overflow: scroll
                font-size: 14px
                th
                    color: gold
                    margin-down: 3px
                    font-size: 24px
                td
                    border: 1px solid gold
                    padding: 2px
                    width: 200px
                    height: 100px     
            .price
                font-size: 14px
                th
                    padding: 8px
        .wrapedDown
            height: 60vh