@import variables
@import mixins
@import keyframes


*
    margin: 0
    padding: 0
    box-sizing: border-box

html
    scroll-behavior: smooth

body
    position: relative
    background-image: url(../img/groundgame.webp)
    background-attachment: fixed
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    z-index: -1
    font-family: 'Oswald', sans-serif
    +flex-display(column, flex-start, wrap)
    @media (max-width: 1200px) and (orientation: portrait)
        background-image: url(../img/groundgameipad.webp)
    user-select: none

@import nav
@import header
@import offer
@import about
@import partners
@import contact
@import findUs
@import footer



