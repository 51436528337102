.about
    display: flex
    color: white
    flex-direction: column
    align-items: center
    position: relative
    background-image: url(../img/groundgame.webp)
    background-attachment: fixed
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    .header
        font-size: 72px
        text-transform: uppercase
        margin: 10vh 0 3% 0
        color: white
        z-index: 1
        .gold
            color: gold
    .trainers
        font-size: 56px
        display: flex
        flex-direction: row
        z-index: 1
        overflow: hidden
        div
            width: 100%
            margin: 0 3%
            display: flex
            flex-flow: column nowrap
            h3
                text-align: center
                border-bottom: 2px solid gold
                margin: 0 50px
                .gold
                    color: gold
            p
                text-align: justify
    &::after
        content: ""
        position: absolute
        top: -25%
        bottom: -30%
        left: 0
  

        width: 100%
        background-color: rgba(0, 0, 0, 0.3)
        z-index: 0
@media (max-width: 600px) and (orientation: portrait)
    .about
        max-width: 100vw
        .header
            font-size: 66px
            margin: 3vh 0 0 0
            .gold
                color: gold
        .trainers
            font-size: 32px
            flex-direction: column
            align-items: center
            overflow: hidden
            div
                width: 90%
                margin: 2% 2%
                display: flex
                flex-flow: column nowrap
                align-items: center
                justify-content: center
                h1
                    font-size: 32px
                    margin: 0 25px
                    border-bottom: 2px solid gold
                p
                    text-align: justify
        &::after   
            background-color: rgba(0, 0, 0, 0.55)
@media (max-width: 1200px) and (orientation: portrait)
    .about
        background-image: url(../img/groundgameipad.webp)
        padding: 0% 0% 4%
        max-width: 100vw
        .header
            font-size: 66px
            margin: 3vh 0 0 0
            .gold
                color: gold
        .trainers
            font-size: 32px
            flex-direction: column
            flex-wrap: wrap
            align-items: center
            width: 100%
            overflow: hidden
            div
                width: 90%
                margin: 2% 2%
                display: flex
                flex-flow: column nowrap
                align-items: center
                justify-content: center
                h3
                    font-size: 40px
                    margin: 0 25px
                    border-bottom: 2px solid gold
                p
                    font-size: 30px
                    text-align: justify
        &::after   
            background-color: rgba(0, 0, 0, 0.55)
           
@media (max-width: 1000px) and (orientation: portrait)
    .about
        max-width: 100vw
        .header
            font-size: 66px
            margin: 3vh 0 0 0
            .gold
                color: gold
        .trainers
            font-size: 32px
            flex-direction: column
            flex-wrap: wrap
            align-items: center
            width: 100%
            overflow: hidden
            div
                width: 90%
                margin: 2% 2%
                display: flex
                flex-flow: column nowrap
                align-items: center
                justify-content: center
                h3
                    font-size: 32px
                    margin: 0 25px
                    border-bottom: 2px solid gold
                p
                    font-size: 24px
                    text-align: justify
        &::after   
            background-color: rgba(0, 0, 0, 0.55)
        

@media (max-width: 823px) and (orientation: landscape)
    .about
  
        .header
            font-size: 52px
            margin: 0 15px
            .gold
                color: gold
        .trainers
            width: 100%
            overflow: hidden
            div
                width: 50%
                h3
                    margin: 0 15px
                    font-size: 30px
                p
                    font-size: 24px
        &::after   
            background-color: rgba(0, 0, 0, 0.55)
         

@media (max-width: 1200px) and (orientation: landscape)
    .about
        min-height: 40vh
        .header
            font-size: 52px
            margin: 0 15px
            .gold
                color: gold
        .trainers
            width: 100%
            overflow: hidden
            div
                width: 50%
                h3
                    margin: 0 15px
                    font-size: 30px
                p
                    font-size: 24px
        &::after   
            background-color: rgba(0, 0, 0, 0.55)
        

@media (min-width: 1201px) and (max-width: 1300px) and (orientation: landscape)
    .about

        &::after   
            background-color: rgba(0, 0, 0, 0.6)

